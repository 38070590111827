<template>
  <div class="row">
    <VeeForm
      class="col"
      v-slot="{ handleSubmit }"
      :validation-schema="schema"
      as="div"
    >
      <div class="alert alert-danger d-none" id="alertError" role="alert"></div>
      <form
        @submit="handleSubmit($event, onSubmit)"
        class="row"
        id="generalityForm"
        ref="generalityForm"
      >
        <div class="col">
          <!-- //* Généralité -->

          <div class="row mb-2">
            <div class="col mt-5">
              <h2 class="section-title">Généralité</h2>
            </div>
          </div>
          <div class="row">
            <div class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <label for="category" class="form-label"
                >Catégorie socioprofessionnelle</label
              >
              <Field
                name="category"
                v-model="category"
                label="catégorie socioprofessionnelle"
                v-slot="{ field, errorMessage }"
              >
                <select
                  class="form-select"
                  id="category"
                  v-bind="field"
                  :class="{ 'is-invalid': errorMessage }"
                  aria-label="Default select example"
                >
                  <option selected value="">Faites un choix</option>
                  <option value="Elève">Elève</option>
                  <option value="Etudiant(e)">Etudiant(e)</option>
                  <option value="Enseignant(e)">Enseignant(e)</option>
                  <option value="Professionnel(le)">Professionnel(le)</option>
                  <option value="other">Autre</option>
                </select>
              </Field>
              <ErrorMessage name="category" as="div" class="invalid-feedback" />
            </div>
            <div
              class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12"
              v-if="category == 'other'"
            >
              <label for="otherCategory" class="form-label"
                >Autre catégorie</label
              >
              <Field
                name="otherCategory"
                v-model="otherCategory"
                type="text"
                label="autre catégorie"
                v-slot="{ field, errorMessage }"
              >
                <input
                  id="otherCategory"
                  v-bind="field"
                  placeholder="Saisissez un texte"
                  class="form-control"
                  :class="{ 'is-invalid': errorMessage }"
                />
              </Field>
              <ErrorMessage
                name="otherCategory"
                as="div"
                class="invalid-feedback"
              />
            </div>
            <div class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <label for="level" class="form-label">Niveau d’étude</label>
              <Field
                name="level"
                label="niveau d’étude"
                v-model="level"
                v-slot="{ field, errorMessage }"
              >
                <select
                  class="form-select"
                  id="level"
                  v-bind="field"
                  :class="{ 'is-invalid': errorMessage }"
                  aria-label="Default select example"
                >
                  <option selected value="">Faites un choix</option>
                  <option value="Terminale">Terminale</option>
                  <option value="Cycle 1 (Bac + 1 à Bac + 3)">
                    Cycle 1 (Bac + 1 à Bac + 3)
                  </option>
                  <option value="Cycle 2 (Bac + 4 à Bac + 5)">
                    Cycle 2 (Bac + 4 à Bac + 5)
                  </option>
                  <option value="Cycle 3">Cycle 3</option>
                </select>
              </Field>
              <ErrorMessage name="level" as="div" class="invalid-feedback" />
            </div>
            <div class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <label for="job" class="form-label">Série/Filière/Métier</label>
              <Field
                name="job"
                type="text"
                label="Série/Filière/Métier"
                v-slot="{ field, errorMessage }"
                v-model="job"
              >
                <input
                  id="job"
                  v-bind="field"
                  placeholder="Saisissez un texte"
                  class="form-control"
                  :class="{ 'is-invalid': errorMessage }"
                />
              </Field>
              <ErrorMessage name="job" as="div" class="invalid-feedback" />
            </div>
            <div class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <label for="establishment" class="form-label"
                >Établissement/Service/Structure d’accueil</label
              >
              <Field
                name="establishment"
                type="text"
                label="Établissement/Service/Structure"
                v-slot="{ field, errorMessage }"
                v-model="establishment"
              >
                <input
                  id="establishment"
                  v-bind="field"
                  placeholder="Saisissez un texte"
                  class="form-control"
                  :class="{ 'is-invalid': errorMessage }"
                />
              </Field>
              <ErrorMessage
                name="establishment"
                as="div"
                class="invalid-feedback"
              />
            </div>
            <div class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <label for="department" class="form-label"
                >Département (Établissement/Service/Structure d’accueil)</label
              >
              <Field
                name="department"
                type="text"
                label="Département"
                v-slot="{ field, errorMessage }"
                v-model="department"
              >
                <input
                  id="department"
                  v-bind="field"
                  placeholder="Saisissez un texte"
                  class="form-control"
                  :class="{ 'is-invalid': errorMessage }"
                />
              </Field>
              <ErrorMessage
                name="department"
                as="div"
                class="invalid-feedback"
              />
            </div>
            <div class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <label for="city" class="form-label"
                >Ville (Établissement/Service/Structure d’accueil)</label
              >
              <Field
                name="city"
                type="text"
                label="Ville"
                v-slot="{ field, errorMessage }"
                v-model="city"
              >
                <input
                  id="city"
                  v-bind="field"
                  placeholder="Saisissez un texte"
                  class="form-control"
                  :class="{ 'is-invalid': errorMessage }"
                />
              </Field>
              <ErrorMessage name="city" as="div" class="invalid-feedback" />
            </div>
            <div class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <label for="country" class="form-label">Pays d’origine</label>
              <Field
                name="country"
                type="text"
                label="pays d’origine"
                v-slot="{ field, errorMessage }"
                v-model="country"
              >
                <input
                  id="country"
                  v-bind="field"
                  placeholder="Saisissez un texte"
                  class="form-control"
                  :class="{ 'is-invalid': errorMessage }"
                />
              </Field>
              <ErrorMessage name="country" as="div" class="invalid-feedback" />
            </div>
            <div class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <label for="residence" class="form-label"
                >Lieu de résidence actuelle</label
              >
              <Field
                name="residence"
                type="text"
                label="Lieu de résidence actuelle"
                v-slot="{ field, errorMessage }"
                v-model="residence"
              >
                <input
                  id="residence"
                  v-bind="field"
                  placeholder="Saisissez un texte"
                  class="form-control"
                  :class="{ 'is-invalid': errorMessage }"
                />
              </Field>
              <ErrorMessage
                name="residence"
                as="div"
                class="invalid-feedback"
              />
            </div>
          </div>

          <!-- //* Pièces jointes -->

          <div class="row">
            <div class="col mt-4">
              <h2 class="section-title">Pièces jointes</h2>
            </div>
          </div>

          <div class="row mb-1 mt-3 text-center">
            <div class="col">
              <!-- Button trigger modal -->
              <button
                type="button"
                class="btn btn-primary btn-lg"
                data-bs-toggle="modal"
                data-bs-target="#attachmentModal"
              >
                Cliquer ici pour ajouter une pièce jointe
              </button>
            </div>
          </div>
          <div class="row mb-2 text-center">
            <div class="col">
              <!-- Button trigger modal -->
              <button
                type="button"
                class="btn btn-outline-primary"
                data-bs-toggle="modal"
                data-bs-target="#pupilsModal"
              >
                Règles de constitution des pièces jointes.
              </button>
            </div>
          </div>
          <div class="row px-md-4">
            <!-- Table -->
            <div class="table-responsive bg-light my-4">
              <h5 class="text-center text-primary mt-3">
                <u>Liste des pièces choisies</u>
              </h5>
              <table class="table table-bordered mt-3">
                <thead>
                  <tr>
                    <th scope="col">Type/Intitulé</th>
                    <th scope="col" style="width: 25%">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-if="attachmentsDatatable.length <= 0"
                    class="text-center"
                  >
                    <td colspan="2">
                      <div class="alert alert-info mt-2" role="alert">
                        Aucune pièce choisie!
                      </div>
                    </td>
                  </tr>
                  <tr
                    v-for="item in attachmentsDatatable"
                    :key="item.nameValue"
                  >
                    <td>{{ item.nameText }}</td>
                    <td class="text-center">
                      <div
                        class="btn-group"
                        role="group"
                        aria-label="Button group"
                      >
                        <a v-if="!isChrome"
                          class="btn btn-primary text-white"
                          href="#"
                          @click.prevent="showFile(item.uploadCallback.path)"
                          role="button"
                          >Voir
                          <font-awesome-icon
                            icon="external-link-alt"
                            class="ms-2"
                          />
                        </a>
                        <button
                          type="button"
                          :data-attachment="item.nameValue"
                          class="btn btn-danger text-white"
                          @click="removeAttachment(item.nameValue)"
                        >
                          Supprimer
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row mb-4">
            <div>
              <button class="btn btn-primary mt-2" type="submit">
                <span v-if="step == 3">Soumettre</span>
                <span v-else>Suivant</span>
              </button>
            </div>
          </div>
        </div>
      </form>

      <!-- Modal -->
      <div
        class="modal fade"
        id="pupilsModal"
        tabindex="-1"
        aria-labelledby="pupilsModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="pupilsModalLabel">
                Règles de constitution des pièces jointes.
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <ul>
                <li>Lettre de motivation <label class="required"></label></li>
                <li>La pièce d'identité <label class="required"></label></li>
                <li>
                  Les trois derniers bulletins<label class="required"></label
                  ><label class="required"></label>
                </li>
                <li>
                  Lettres de recommandation du Responsable d’école et des
                  professeurs de Maths et de PCT
                </li>
                <li>
                  Autorisation parentale (à remplir par le parent pour le compte
                  de l’apprenant mineur)
                </li>
                <li>Formulaire d’informations générales</li>
              </ul>

              <ul class="text-muted list-unstyled">
                <li>
                  <label class="required"></label> Obligatoire pour tout le
                  monde.
                </li>
                <li>
                  <label class="required"></label>
                  <label class="required"></label>
                  Obligatoire pour les élèves et les étudiants.
                </li>
              </ul>
            </div>
            <!-- <div class="modal-body" v-else>
              <ul>
                <li>Lettre de motivation (1Mo au plus)</li>
              </ul>
            </div> -->
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Fermer
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal Pièces -->
      <div
        class="modal fade"
        id="attachmentModal"
        tabindex="-1"
        aria-labelledby="attachmentModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="attachmentModalLabel">
                Ajouter une pièce.
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <VeeForm
                class="col"
                v-slot="{ handleSubmit }"
                :validation-schema="attachmentsSchema"
                as="div"
              >
                <form
                  @submit="handleSubmit($event, onAttachmentsSubmit)"
                  id="attachmentForm"
                  ref="attachmentForm"
                >
                  <div class="mb-4">
                    <label for="attachmentType" class="form-label"
                      >Type de pièce :</label
                    >
                    <Field
                      name="attachmentType"
                      label="Type de pièce"
                      v-slot="{ field, errorMessage }"
                    >
                      <select
                        class="form-select"
                        id="attachmentType"
                        v-model="attachmentType"
                        v-bind="field"
                        :class="{ 'is-invalid': errorMessage }"
                        aria-label="Default select example"
                      >
                        <option selected value="">
                          Cliquer pour faire un choix
                        </option>
                        <option
                          v-for="item in attachmentTypes"
                          :key="item.name"
                          :value="item.name"
                          :disabled="item.disabled"
                        >
                          {{ attachmentTexts[item.name] }}
                        </option>
                      </select>
                    </Field>
                    <ErrorMessage
                      name="attachmentType"
                      as="div"
                      class="invalid-feedback"
                    />
                  </div>
                  <div class="mb-4">
                    <label for="attachment" class="form-label"
                      >Sélectionner un fichier :</label
                    >
                    <Field
                      name="attachment"
                      label="Pièce jointe"
                      v-slot="{ handleChange, errorMessage, handleBlur }"
                    >
                      <input
                        class="form-control"
                        id="attachment"
                        :class="{ 'is-invalid': errorMessage }"
                        type="file"
                        accept="application/pdf"
                        @change="handleChange"
                        @blur="handleBlur"
                        aria-describedby="fileHelpBlock"
                        ref="attachment"
                      />
                      <div id="fileHelpBlock" class="form-text">
                        Seulement les fichiers <b>PDF</b> sont acceptés. La
                        taille du fichier ne doit pas dépasser les <b>2 Mo</b>.
                      </div>
                    </Field>
                    <ErrorMessage
                      name="attachment"
                      as="div"
                      class="invalid-feedback"
                    />
                  </div>
                  <button
                    type="submit"
                    class="btn btn-primary d-none"
                    id="submitAttachmentFormButton"
                  >
                    Soumettre
                  </button>
                </form>
              </VeeForm>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Fermer
              </button>
              <button
                type="button"
                class="btn btn-primary"
                @click.prevent="submitAttachmentForm()"
              >
                Soumettre
              </button>
            </div>
          </div>
        </div>
      </div>
    </VeeForm>
  </div>
</template>

<script>
import { Form as VeeForm, Field, ErrorMessage } from "vee-validate";
import { defineRule } from "vee-validate";
import { required } from "@vee-validate/rules";
import { Modal } from "bootstrap";
import UAParser from "ua-parser-js";

defineRule("required", required);
defineRule("required_if", (value, [category]) => {
  if (category == "other") {
    if (!value) {
      return "Ce champs est obligatoire";
    }
  }

  return true;
});

import { setLocale } from "@vee-validate/i18n";
import { configure } from "vee-validate";
import { localize } from "@vee-validate/i18n";
import fr from "@vee-validate/i18n/dist/locale/fr.json";
configure({
  generateMessage: localize({
    fr,
  }),
});
setLocale("fr");

import { library } from "@fortawesome/fontawesome-svg-core";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faInfoCircle, faExternalLinkAlt);

export default {
  name: "Generality",
  inject: [
    "ToastSuccess",
    "ToastError",
    "currentYearUrl",
    "AlertWarning",
    "AlertError",
  ],
  components: {
    // Rename form to avoid confusion with native `form`
    VeeForm,
    Field,
    ErrorMessage,
    FontAwesomeIcon,
  },
  props: {
    previousData: Object,
    step: Number,
    previousAttachments: Object,
  },
  mounted() {
    this.formatDataTable(this.previousAttachments.attachments);
    localStorage.setItem("currentStep", 1);
    this.attachmentModal = new Modal(
      document.getElementById("attachmentModal"),
      {
        keyboard: false,
      }
    );

    let that = this;

    document
      .getElementById("attachmentModal")
      .addEventListener("hidden.bs.modal", function () {
        that.resetAttachmentForm();
      });
  },
  data() {
    const schema = {
      category: "required",
      otherCategory: "required_if:@category",
      level: "required",
      job: "required",
      establishment: "required",
      city: "required",
      country: "required",
      department: "required",
      residence: "required",
    };

    const attachmentsSchema = {
      attachmentType: "required",
      attachment: "required",
    };

    let attachmentTypes = [
      { name: "bulletin-1", disabled: false },
      { name: "bulletin-2", disabled: false },
      { name: "bulletin-3", disabled: false },
      { name: "piece-identite", disabled: false },
      { name: "lettre-motivation", disabled: false },
      { name: "recommandation-professeur", disabled: false },
      { name: "formulaire-information", disabled: false },
      { name: "autorisation-parentale", disabled: false },
    ];
    let attachmentTexts = {
      "bulletin-1": "1er bulletin",
      "bulletin-2": "2ème bulletin",
      "bulletin-3": "3ème bulletin",
      "piece-identite": "Pièce d'identité",
      "lettre-motivation": "Lettre de motivation",
      "recommandation-professeur":
        "Recommandation du professeur de mathématiques ou sciences physiques",
      "formulaire-information": "Formulaire d’informations générales",
      "autorisation-parentale": "Autorisation parentale pour les mineurs",
    };

    let attachmentsDatatable = [];

    return {
      schema,
      attachmentsSchema,
      category: this.previousData.category,
      otherCategory: this.previousData.otherCategory,
      level: this.previousData.level,
      job: this.previousData.job,
      establishment: this.previousData.establishment,
      city: this.previousData.city,
      country: this.previousData.country,
      department: this.previousData.department,
      residence: this.previousData.residence,
      attachmentType: "",
      attachmentTypes: attachmentTypes,
      attachmentTexts: attachmentTexts,
      attachmentsDatatable: attachmentsDatatable,
      parser: new UAParser().getResult(),
    };
  },
  methods: {
    formatDataTable(attachments) {
      attachments.forEach((attachment) => {
        let path = Object.prototype.hasOwnProperty.call(attachment, "path")
          ? attachment.path
          : "";

        this.attachmentsDatatable.push({
          nameValue: attachment.attachment_type,
          nameText: this.attachmentTexts[attachment.attachment_type],
          uploadCallback: {
            path: path,
          },
        });

        this.attachmentTypes.forEach((element, index) => {
          if (attachment.attachment_type == element.name) {
            this.attachmentTypes[index].disabled = true;
          }
        });
      });
    },
    onSubmit(values, actions) {
      // let $this = this
      // let promiseValues = new Promise(function (resolve) {
      //   console.log($this.$refs)
      //   values.step = 1;
      //   resolve(values);
      // });

      this.beforeSendPromise(values, this).then((formData) => {
        this.$http
          .post("new-candidacies", formData)

          .then(() => {
            if (this.step != 3) {
              this.$emit("to-next");
            } else {
              this.ToastSuccess.fire({
                title: "Dossier envoyé avec succès",
              });
            }
          })
          .catch((error) => {
            if (error.response.status == 422) {
              this.ToastError.fire({
                title: "Veuillez bien remplir le formulaire et réessayez.",
              });

              let errors = error.response.data.errors;
              for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key)) {
                  const element = errors[key];
                  actions.setFieldError(key, element);
                }
              }
            } else {
              this.ToastError.fire({
                title: "Une erreur s'est produite, veuillez réessayer.",
              });
            }
          });
      });
    },
    beforeSendPromise(values, $this) {
      return new Promise(function (resolve, reject) {
        if ($this.validateAttachmentsDatatable()) {
          values.step = 1;
          // values.attachments = $this.$refs.attachments.files.item(0);
          let formData = new FormData();
          formData.append("step", 1);
          formData.append("category", values.category);
          formData.append("otherCategory", values.otherCategory);
          formData.append("level", values.level);
          formData.append("job", values.job);
          formData.append("establishment", values.establishment);
          formData.append("city", values.city);
          formData.append("country", values.country);
          formData.append("department", values.department);
          formData.append("residence", values.residence);

          resolve(formData);
        } else {
          reject();
        }
      });
    },
    validateAttachmentsDatatable() {
      let foundPieces = 0;

      this.attachmentsDatatable.forEach((attachment) => {
        if (attachment.nameValue == "piece-identite") {
          foundPieces++;
        }
        if (attachment.nameValue == "lettre-motivation") {
          foundPieces++;
        }
      });

      if (foundPieces == 2) {
        return true;
      } else {
        this.AlertError.fire({
          icon: "error",
          title: "Echec !",
          html: "La <b>Pièce d'identité</b> et la <b>Lettre de motivation</b> sont obligatoires.",
        });
        return false;
      }
    },
    submitAttachmentForm() {
      document.getElementById("submitAttachmentFormButton").click();
    },
    onAttachmentsSubmit(values, actions) {
      let formData = new FormData();
      formData.append("attachment_type", this.attachmentType);
      formData.append("attachment", this.$refs.attachment.files.item(0));

      this.$http
        .post("attachment/store", formData)
        .then((response) => {
          this.ToastSuccess.fire({
            title: "Pièce envoyé avec succès",
          });
          this.addAttachmentToDataTable(response.data);
          this.attachmentModal.hide();
        })
        .catch((error) => {
          if (
            Object.prototype.hasOwnProperty.call(error.response, "status") &&
            error.response.status == 422
          ) {
            if (
              Object.prototype.hasOwnProperty.call(
                error.response.data,
                "errors"
              ) &&
              Object.prototype.hasOwnProperty.call(
                error.response.data.errors,
                "attachment"
              )
            ) {
              this.ToastError.fire({
                title:
                  "La taille du fichier ne peut pas dépasser 2Mo. Veuillez bien remplir le formulaire et réessayer.",
              });
            } else {
              this.ToastError.fire({
                title: "Veuillez bien remplir le formulaire et réessayez.",
              });
            }

            let errors = error.response.data.errors;
            for (const key in errors) {
              if (Object.prototype.hasOwnProperty.call(errors, key)) {
                const element = errors[key];
                actions.setFieldError(key, element);
              }
            }
          } else {
            this.ToastError.fire({
              title: "Une erreur s'est produite, veuillez réessayer.",
            });
          }
        });
    },
    addAttachmentToDataTable(uploadCallback) {
      this.attachmentsDatatable.push({
        nameValue: this.attachmentType,
        nameText: this.attachmentTexts[this.attachmentType],
        uploadCallback: uploadCallback,
      });

      this.attachmentTypes.forEach((element, index) => {
        if (element.name == this.attachmentType) {
          this.attachmentTypes[index].disabled = true;
        }
      });
    },
    resetAttachmentForm() {
      // Resetting Values
      this.attachmentType = "";
      this.$refs.attachment.value = "";

      // Wait until the models are updated in the UI
      this.$nextTick(() => {
        // this.$refs.attachmentForm.reset();
      });
    },
    removeAttachment(attachmentType) {
      this.AlertWarning.fire({
        title: "Attention!",
        text: "Voulez-vous vraiment supprimer cette pièce ?",
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, supprimer!",
        cancelButtonText: "Annuler",
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteFiles(attachmentType);
        }
      });
    },
    deleteFiles(attachmentType) {
      let path;
      this.attachmentsDatatable.forEach((element, index) => {
        if (element.nameValue == attachmentType) {
          path = this.attachmentsDatatable[index].uploadCallback.path;
        }
      });

      let formData = new FormData();
      formData.append("path", path);

      this.$http
        .post("/attachment/delete", formData)
        .then(() => {
          try {
            this.attachmentTypes.forEach((element, index) => {
              if (element.name == attachmentType) {
                this.attachmentTypes[index].disabled = false;
              }
            });

            this.attachmentsDatatable.forEach((element, index) => {
              if (element.nameValue == attachmentType) {
                this.attachmentsDatatable.splice(index, 1);
              }
            });

            this.ToastSuccess.fire({
              title: "Pièce supprimée !",
            });
          } catch (error) {
            console.log(error);
          }
        })
        .catch(function (error) {
          if (error.response) {
            this.AlertError.fire({
              icon: "error",
              title: "Echec !",
              text: "Désolé, une erreur s'est produite. Veuillez réessayer.",
            });
          }
        });
    },
  showFile(filename) {
      if (!this.isChrome) {
        var windowReference = window.open();
      }
      this.$http({
        url: "attachment/show",
        method: "GET",
        params: {
          filename: filename,
          isChrome: this.isChrome,
        },
        responseType: this.userAgentResponseType,
      })
        .then((response) => {
          if (this.isChrome) {
            let base64URL = response.data.data.base64;
            let win = window.open();
            win.document.write(
              '<iframe src="' +
                base64URL +
                '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
            );
          } else {
            const file = new Blob([response.data]);
            const fileUrl = URL.createObjectURL(file);
            windowReference.location.assign(fileUrl);
          }
        })
        .catch(function (error) {
          if (error.response) {
            this.ToastError.fire({
              icon: "error",
              title: "Echec !",
              text: "Désolé, une erreur s'est produite. Veuillez réessayer.",
            });
          }
        });
    },
  },
  computed: {
    userAgentResponseType() {
      if (this.parser.browser.name == "Chrome") {
        return "json";
      } else {
        return "blob";
      }
    },

    isChrome() {
      if (this.parser.browser.name == "Chrome") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.form-control,
.form-select {
  line-height: 2;
}

.section-title {
  border-bottom: 3px solid #3490dc;
}
</style>
