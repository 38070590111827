<template>
  <div class="container px-sm-0 bg-light">
    <div class="row adjust-margin">
      <div class="col">
        <div class="container px-sm-5">
          <keep-alive>
            <generality
              v-if="currentStep == 1"
              v-on:to-next="currentStep++"
              :previousData="step1"
              :previousAttachments="step2"
              :step="userProfile.step"
            ></generality>
          </keep-alive>
          <keep-alive>
            <evaluation
              v-if="currentStep == 2"
              v-on:to-next="currentStep++"
              v-on:to-previous="currentStep--"
              v-on:evaluation-sent="updateUserStep"
              :previousData="step3"
              :step="userProfile.step"
            ></evaluation>
          </keep-alive>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Generality from "../components/Generality.vue";
import Evaluation from "../components/Evaluation.vue";

export default {
  name: "NewCandidacy",
  inject: ["ToastSuccess", "ToastError", "currentYearUrl"],
  components: {
    Generality,
    Evaluation,
  },
  mounted() {
    // document.querySelector("body").style.backgroundColor = "#446eada1";
  },
  created() {
    this.getAllSteps();
  },
  data() {
    return {
      currentStep: 0,
      firstStep: 1,
      finalStep: 7,
      step1: {},
      step2: {},
      step3: {},
      userProfile: {},
    };
  },
  methods: {
    getAllSteps() {
      this.$http.get("new-candidacies").then(async (result) => {
        await this.setAllLocalSteps(result);
        
        if (localStorage.getItem("currentStep")) {
          this.currentStep = parseInt(localStorage.getItem("currentStep"));
        } else {
          this.currentStep = 1;
        }
      });
    },
    async setAllLocalSteps(result) {
      this.step1 = result.data.step1;
      this.step2 = result.data.step2;
      this.step3 = result.data.step3;
      this.userProfile = result.data.userProfile;
      // this.displayUserInformations(result.data.userProfile)
    },
    displayUserInformations(userProfile) {
      document.getElementById('navbarLastName').textContent = userProfile.lastName
      document.getElementById('navbarFirstName').textContent = userProfile.firstName
    },
    updateUserStep() {
      this.userProfile.step = 3
    }
  },
};
</script>

<style scoped>
@media all and (max-width: 991px) {
  /* .adjust-margin {
    margin: 0px !important;
  } */
}
</style>